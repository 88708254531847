<template>
	<div>
		<el-dialog :draggable="true" width="60%" top="5vh" v-model="outerVisible" @close="Reset" :title="title">
			<div class="dialog">
				<el-form ref="ruleForm" label-width="120px">
					<el-form-item label="姓名">
						<span class="xx">*</span>
						<el-input clearable v-model="ruleForm.name" placeholder="请输入姓名"></el-input>
					</el-form-item>
					<el-form-item label="手机号码">
						<span class="xx">*</span>
						<el-input type="number" clearable v-model="ruleForm.tel" placeholder="请输入手机号码"></el-input>
					</el-form-item>
					<el-form-item label="身份证">
						<span class="xx">*</span>
						<el-input clearable v-model="ruleForm.idcard" placeholder="请输入身份证"></el-input>
					</el-form-item>
					<el-form-item label="备注说明">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.remarks" placeholder="请输入备注说明"></el-input>
					</el-form-item>
					<el-form-item label="申请表">
						<span class="xx"></span>
						<div class="dialog-up">
							<!-- 文件 -->
							<el-upload multiple accept=".pdf,.doc,.docx,.xlm,.zip,.ppt,.xls,.xlsx,.rtf,.word"
								:headers="Headers" :action="ActionUrl" :on-success="UploadSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
						</div>
					</el-form-item>
					<el-form-item label=" " v-if="appenList.length>0">
						<div class="dialog-list">
							<div class="upload-li" v-for="(item,index) in appenList" :key="index">
								<div class="names">
									<p style="width: 100px;color: #333;">原表名称:</p>
									<el-input v-model="item.OriginalName" placeholder="请输入原表名称"></el-input>
								</div>
								<div class="names">
									<p style="width: 100px;color: #333;">原表链接:</p>
									<el-input disabled v-model="item.url" placeholder="请输入原表链接"></el-input>
								</div>
								<p @click="delUpload(index)">删除</p>
							</div>
						</div>
					</el-form-item>

					<el-form-item label="申请表图片" @click="binType(1)">
						<span class="xx"></span>
						<div class="dialog-up">
							<!-- 预览图 -->
							<div class="imgList" v-if="imgList1.length>0" v-for="(item,index) in imgList1" :key="index">
								<img class="dialog-img" :src="item" />
								<p class="dele" @click="deleImg(index,1)">删除</p>
							</div>
							<el-upload accept=".jpg,.png" :headers="Headers" :action="ActionUrl"
								:on-success="imgSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
						</div>
					</el-form-item>

					<el-form-item label="户口本复印件" @click="binType(2)">
						<span class="xx">*</span>
						<div class="dialog-up">
							<!-- 预览图 -->
							<div class="imgList" v-if="imgList2.length>0" v-for="(item,index) in imgList2" :key="index">
								<img class="dialog-img" :src="item" />
								<p class="dele" @click="deleImg(index,2)">删除</p>
							</div>
							<el-upload accept=".jpg,.png" :headers="Headers" :action="ActionUrl"
								:on-success="imgSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
						</div>
					</el-form-item>

					<el-form-item label="身份证正反复印件" @click="binType(3)">
						<span class="xx">*</span>
						<div class="dialog-up">
							<!-- 预览图 -->
							<div class="imgList" v-if="imgList3.length>0" v-for="(item,index) in imgList3" :key="index">
								<img class="dialog-img" :src="item" />
								<p class="dele" @click="deleImg(index,3)">删除</p>
							</div>
							<el-upload accept=".jpg,.png" :headers="Headers" :action="ActionUrl"
								:on-success="imgSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
						</div>
					</el-form-item>

					<el-form-item label="社保卡存折复印件" @click="binType(4)">
						<span class="xx">*</span>
						<div class="dialog-up">
							<!-- 预览图 -->
							<div class="imgList" v-if="imgList4.length>0" v-for="(item,index) in imgList4" :key="index">
								<img class="dialog-img" :src="item" />
								<p class="dele" @click="deleImg(index,4)">删除</p>
							</div>
							<el-upload accept=".jpg,.png" :headers="Headers" :action="ActionUrl"
								:on-success="imgSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
						</div>
					</el-form-item>

					<el-form-item label="村委会初审并提出审核意见" @click="binType(5)">
						<span class="xx">*</span>
						<div class="dialog-up">
							<!-- 预览图 -->
							<div class="imgList" v-if="imgList5.length>0" v-for="(item,index) in imgList5" :key="index">
								<img class="dialog-img" :src="item" />
								<p class="dele" @click="deleImg(index,5)">删除</p>
							</div>
							<el-upload accept=".jpg,.png" :headers="Headers" :action="ActionUrl"
								:on-success="imgSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
						</div>
					</el-form-item>
					<el-form-item label="公示材料" @click="binType(6)">
						<span class="xx">*</span>
						<div class="dialog-up">
							<!-- 预览图 -->
							<div class="imgList" v-if="imgList6.length>0" v-for="(item,index) in imgList6" :key="index">
								<img class="dialog-img" :src="item" />
								<p class="dele" @click="deleImg(index,6)">删除</p>
							</div>
							<el-upload accept=".jpg,.png" :headers="Headers" :action="ActionUrl"
								:on-success="imgSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
						</div>
					</el-form-item>


				</el-form>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="outerVisible = false">取消</el-button>
					<el-button size="mini" class="dialog-btn el-white" type="primary" @click="submitForm">确定
					</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/bonus";
	export default {
		data() {
			return {
				Headers: {
					token: ''
				},
				ActionUrl: '',
				outerVisible: false,
				title: '新增',
				list: [],
				ruleForm: {
					type: 3,
					name: '',
					tel: '',
					idcard: '',
					remarks: '',
					apply_sheet: '',
					apply_imgpath: '',
					idcard_path: '',
					social_security: '',
					social_security_passbook: '',
					village_review_idea: '',
					publicity_materials: '',
					work_certificate: '',
					salary_certificate: '',
					hukou_book: ''
				},
				imgList1: [],
				imgList2: [],
				imgList3: [],
				imgList4: [],
				imgList5: [],
				imgList6: [],
				appenList: [],
				type: 1,
				mid:'',
				id:''
			}
		},
		mounted() {
			// 上转图片url
			this.Headers.token = sessionStorage.getItem('token')
			this.Headers.appid = sessionStorage.getItem('appid')
			this.Headers.nonce = sessionStorage.getItem('nonce')
			this.Headers.timestamp = sessionStorage.getItem('timestamp')
			this.Headers.sign = sessionStorage.getItem('sign')

			this.ActionUrl = sessionStorage.getItem('baseURL') + '/Upload/FormUpload'
		},
		methods: {
			//  类型
			binType(val) {
				this.type = val
			},
			// 删除附件
			delUpload(index) {
				this.appenList.splice(index, 1)
			},
			// 附件
			UploadSuccess(res, file) {
				this.appenList.push(res.data)
			},
			// 删除图片
			deleImg(index, type) {
				if (type == 1) {
					this.imgList1.splice(index, 1)
				}
				if (type == 2) {
					this.imgList2.splice(index, 1)
				}
				if (type == 3) {
					this.imgList3.splice(index, 1)
				}
				if (type == 4) {
					this.imgList4.splice(index, 1)
				}
				if (type == 5) {
					this.imgList5.splice(index, 1)
				}
				if (type == 6) {
					this.imgList6.splice(index, 1)
				}
			},
			// 图片
			imgSuccess(res, file) {
				if (this.type == 1) {
					this.imgList1.push(res.data.url)
				}
				if (this.type == 2) {
					this.imgList2.push(res.data.url)
				}
				if (this.type == 3) {
					this.imgList3.push(res.data.url)
				}
				if (this.type == 4) {
					this.imgList4.push(res.data.url)
				}
				if (this.type == 5) {
					this.imgList5.push(res.data.url)
				}
				if (this.type == 6) {
					this.imgList6.push(res.data.url)
				}
			},
			// 获取信息
			getinfo() {
				this.$http.post(api.bonusapplyread, {
						id:  this.mid
					})
					.then((res) => {
						if (res.code == 200) {
							this.ruleForm = res.data
							this.ruleForm.id = this.id

							this.appenList = res.data.apply_sheet ? JSON.parse(res.data.apply_sheet) : []
							this.imgList1 = res.data.apply_imgpath ? res.data.apply_imgpath.split(",") : []
							this.imgList2 = res.data.hukou_book ? res.data.hukou_book.split(",") : []
							this.imgList3 = res.data.idcard_path ? res.data.idcard_path.split(",") : []
							this.imgList4 = res.data.social_security_passbook ? res.data.social_security_passbook
								.split(",") : []
							this.imgList5 = res.data.village_review_idea ? res.data.village_review_idea.split(",") :
							[],
								this.imgList6 = res.data.publicity_materials ? res.data.publicity_materials.split(
								",") : []
							delete this.ruleForm.create_time
						}
					})
			},
			// 显示隐藏
			show(val, id, mid) {
				this.title = val
				if(mid) {
					this.id = id
					this.mid = mid
					this.getinfo()
				}
				this.outerVisible = !this.outerVisible
			},
			// 清空数据
			Reset() {
				for (let key in this.ruleForm) {
					this.ruleForm[key] = ''
				}
				this.appenList = []
				this.imgList1 = []
				this.imgList2 = []
				this.imgList3 = []
				this.imgList4 = []
				this.imgList5 = []
				this.imgList6 = []
				this.ruleForm.type = 3
				delete this.ruleForm.id
				delete this.ruleForm.delete_time
			},
			//新增/编辑
			submitForm() {
				this.ruleForm.apply_sheet = this.appenList.length > 0 ? JSON.stringify(this.appenList) : ''
				this.ruleForm.apply_imgpath = this.imgList1.length > 0 ? this.imgList1.join(",") : ''
				this.ruleForm.hukou_book = this.imgList2.length > 0 ? this.imgList2.join(",") : ''
				this.ruleForm.idcard_path = this.imgList3.length > 0 ? this.imgList3.join(",") : ''
				this.ruleForm.social_security_passbook = this.imgList4.length > 0 ? this.imgList4.join(",") : ''
				this.ruleForm.village_review_idea = this.imgList5.length > 0 ? this.imgList5.join(",") : ''
				this.ruleForm.publicity_materials = this.imgList6.length > 0 ? this.imgList6.join(",") : ''
				if (this.ruleForm.name === '') {
					ElMessage({
						message: '请输入姓名',
						type: 'warning',
					})
				} else if (this.ruleForm.tel === '') {
					ElMessage({
						message: '请输入手机号码',
						type: 'warning',
					})
				} else if (this.ruleForm.idcard === '') {
					ElMessage({
						message: '请输入身份证',
						type: 'warning',
					})
				} else if (!this.ruleForm.apply_sheet && !this.ruleForm.apply_imgpath) {
					ElMessage({
						message: '请上传申请表或申请表图片',
						type: 'warning',
					})
				} else if (this.ruleForm.hukou_book === '') {
					ElMessage({
						message: '请上传户口本复印件',
						type: 'warning',
					})
				} else if (this.ruleForm.idcard_path === '') {
					ElMessage({
						message: '请上传身份证正反面',
						type: 'warning',
					})
				} else if (this.ruleForm.social_security_passbook === '') {
					ElMessage({
						message: '请上传社保卡存折复印件',
						type: 'warning',
					})
				} else if (this.ruleForm.village_review_idea === '') {
					ElMessage({
						message: '请上传村委会初审并提出审核意见',
						type: 'warning',
					})
				} else if (this.ruleForm.publicity_materials === '') {
					ElMessage({
						message: '请上传公示材料',
						type: 'warning',
					})
				} else {
					if (this.title == '新增') {
						this.add()
					} else {
						this.edit()
					}
				}
			},
			// 编辑
			edit() {
				this.$http.post(api.applyupdate, this.ruleForm)
					.then((res) => {
						if (res.code == 200) {
							ElMessage({
								type: 'success',
								message: '编辑成功',
							})
							this.$emit('SubmitForm')
							this.outerVisible = false
						}
					})
			},
			// 新增
			add() {
				this.$http.post(api.adds, this.ruleForm)
					.then((res) => {
						if (res.code == 200) {
							ElMessage({
								type: 'success',
								message: '新增成功',
							})
							this.outerVisible = false
							// 刷新列表
							this.$emit('SubmitForm')
						}
					})
			}
		}
	}
</script>
<style scoped lang="scss">
	.names {
		display: flex;
		align-items: center;
	}

	.Draggable {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}

	.dialog-up {
		.top {
			font-size: 12px;
			position: absolute;
			top: 0;
			height: 20px;
			line-height: 20px;
			right: 0;

			i {
				padding: 3px;
				cursor: pointer;
				font-size: 14px;
				margin-left: 5px;
				color: #FFFFFF;
				background: rgba(0, 0, 0, 0.5);
			}
		}
	}

	.dialog-up-left {
		float: left;
		margin-left: 20px;
	}

	.upload-li {
		font-size: 12px;
		display: flex;
	}

	.upload-li p {
		cursor: pointer;
		color: #999999;
		margin-left: 20px;
	}

	.upload-li p:hover {
		color: #00ad79;
	}

	.dialog-title {
		margin: 40px 0;
		font-size: 16px;
	}

	.dialog-img-box {
		cursor: move;
		position: relative;
		height: 80px;
		overflow: hidden;
		width: 80px;
		margin-right: 10px;
		border: 1px solid #dcdde0;
		border-radius: 4px;
		text-align: center;
		margin-bottom: 10px;
	}

	.dialog-img-box p {
		font-size: 12px;
		width: 80px;
		position: absolute;
		bottom: 0;
		height: 20px;
		line-height: 20px;
		left: 0;
		text-align: center;
		background: rgba(0, 0, 0, 0.5);
		color: #FFFFFF;
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		line-clamp: 1;
		-webkit-box-orient: vertical;
	}
</style>
